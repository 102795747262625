import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "beaconx-all.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                <div>
                    <div className="main-text"> Beacon <span>X.</span></div>
                    <div className="sub-text">  Controle voor je klanten.    </div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className = 'child'>
                    <h3> Geen gemiste ophaalmomenten. </h3>
                    <p>
                    Met onze real-time voorspellingen van de aankomsttijd van het voertuig zullen je klanten nooit meer een afvalophaalmoment missen. 
                    Alle informatie over de contracten en geplande pick-ups is zichtbaar in de app.
                    </p>
                </div>
                <div className = 'child right'>
                    <h3> De beste kwaliteit. </h3>
                    <p>
                    Geef je klanten alle informatie over de ingezamelde reststromen met handige statistieken. Wat gebeurt er met het afval nadat het is opgehaald? 
                    Hoeveel afval hebben ze deze maand geproduceerd? Samen werken we toe naar minder afval van betere kwaliteit. 
                    </p>
                </div>
                <div className = 'child'>
                    <h3> Dynamische inzameling.  </h3>
                    <p>
                    Via de app kunnen klanten ophaalmomenten of extra diensten aanvragen. Onze algoritmen zorgen ervoor dat 
                    deze extra aanvragen optimaal vervuld worden.
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Hero