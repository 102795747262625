import React from "react"
import { FaPhoneVolume, FaRecycle, FaAddressCard} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
            <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Functionaliteiten </div>
                <div className="sub-text">  Kies uit onze geavanceerde modules om de applicatie volledig te personaliseren naar 
                jouw proces.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Beheer.</div>
                    <div> <FaAddressCard size={50} color={"#7FACD6"}/> </div>
                    <div>De klant ziet de verwachtte aankomsttijd van het voertuig.</div>
                    <div>De klant heeft inzicht in zijn contract en ophaalmomenten. </div>
                    <div>Makkelijk contractwijzigingen doorvoeren. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Inzicht in het afval.</div>
                    <div> <FaRecycle size={50} color={"#7FACD6"}/> </div>
                    <div>Een analyse tool voor de klant met in welke afvalstromen worden opgehaald. </div>
                    <div>Statistieken over duurzaamheid en kwaliteit van afval. </div>
                    <div>Laat de klant zijn afval volgen van ophalen tot verwerking. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Moeiteloos contact.</div>
                    <div> <FaPhoneVolume size={50} color={"#7FACD6"}/> </div>
                    <div>Laat klanten makkelijk een extra ophaalmoment aanvragen. </div>
                    <div>Communiceer moeiteloos met de klant.</div>
                    <div>Automatische verwerking van klantmeldingen. </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features